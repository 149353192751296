<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :max-width="1200"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="closeModal(false)">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <template v-if="canEdit">
            <DialogDetachedContracts
              v-if="model.hasDetachedContracts"
              :employeeId="employeeId"
              :customerId="customerId"
              :dependentId="selectItem.id"
              @updatePage="updatePage"
            />
          </template>

          <v-row dense>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <BaseAvailableEmployeeContract
                id="contracts"
                name="contracts"
                required
                :rules="[requiredLength]"
                :disabled="edit"
                :employeeId="employeeId"
                v-model="model.contracts"
              />
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6" v-if="!edit">
              <v-checkbox
                id="isExistingDependent"
                name="isExistingDependent"
                class="ma-1"
                color="primary"
                label="Deseja vincular um dependente já existente?"
                v-model="isExistingDependent"
              />
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
              v-if="!edit && isExistingDependent"
            >
              <BaseAvailableDependents
                id="dependentId"
                name="dependentId"
                required
                :rules="[requiredLength]"
                :employeeId="employeeId"
                v-model="dependentId"
              />
            </v-col>
          </v-row>

          <template v-if="!isExistingDependent">
            <v-divider class="mb-5" />

            <v-row dense>
              <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                <h5 class="mb-4">Dados cadastrais</h5>
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                <v-text-field
                  id="name"
                  name="name"
                  label="Nome"
                  dense
                  outlined
                  required
                  :rules="[required]"
                  v-model="model.name"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <v-text-field
                  id="document"
                  name="document"
                  label="CPF"
                  dense
                  outlined
                  clearable
                  :rules="checkCpfRequired ? [required, cpf] : []"
                  v-mask="'###.###.###-##'"
                  v-model="model.document"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <BaseDatePicker
                  id="birthDate"
                  name="birthDate"
                  label="Data de nascimento"
                  required
                  :rules="[required]"
                  :disabled="model.situation === 'Ativo'"
                  v-model="model.birthDate"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <BaseKinship
                  id="kinship"
                  name="kinship"
                  label="Grau de parentesco"
                  required
                  :rules="[required]"
                  v-model="model.kinship"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <v-text-field
                  id="birthCertificate"
                  name="birthCertificate"
                  label="DNV"
                  dense
                  outlined
                  :rules="checkDnvRequired ? [required] : []"
                  v-model="model.birthCertificate"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <BaseMaritalStatus
                  id="maritalStatus"
                  name="maritalStatus"
                  label="Estado civil"
                  required
                  :rules="[required]"
                  v-model="model.maritalStatus"
                />
              </v-col>
              <v-col
                cols="12"
                xl="2"
                lg="2"
                md="2"
                sm="6"
                v-if="
                  model.maritalStatus === 'Casado(a)' ||
                  model.maritalStatus === 'União estável'
                "
              >
                <BaseDatePicker
                  id="eventDate"
                  name="eventDate"
                  :label="
                    model.maritalStatus === 'União estável'
                      ? 'Data do evento'
                      : 'Data de casamento'
                  "
                  required
                  :rules="[required]"
                  v-model="model.eventDate"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <v-autocomplete
                  id="gender"
                  name="gender"
                  label="Sexo"
                  dense
                  outlined
                  required
                  :rules="[required]"
                  :items="['Masculino', 'Feminino', 'Outro']"
                  v-model="model.gender"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <v-text-field
                  id="mobileNumber"
                  name="mobileNumber"
                  label="DDD e Celular"
                  dense
                  outlined
                  v-model="model.mobileNumber"
                  v-mask="'(##) #####-####'"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                <v-text-field
                  id="email"
                  name="email"
                  label="Email"
                  dense
                  outlined
                  :rules="[email]"
                  v-model="model.email"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <v-text-field
                  id="nationalIdCard"
                  name="nationalIdCard"
                  label="RG"
                  dense
                  outlined
                  :rules="checkNationalIdRequired ? [required] : []"
                  v-model="model.nationalIdCard"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <BaseDatePicker
                  id="nationalIdCardDispatchDate"
                  name="nationalIdCardDispatchDate"
                  label="Data de expedição"
                  :rules="checkNationalIdRequired ? [required] : []"
                  v-model="model.nationalIdCardDispatchDate"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <v-text-field
                  id="nationalIdCardIssuer"
                  name="nationalIdCardIssuer"
                  label="Orgão emissor"
                  dense
                  outlined
                  :rules="checkNationalIdRequired ? [required] : []"
                  v-model="model.nationalIdCardIssuer"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <BaseNumber
                  id="susCardNumber"
                  name="susCardNumber"
                  label="Cartão SUS"
                  maxlength="15"
                  v-model="model.susCardNumber"
                />
              </v-col>
              <v-col
                cols="12"
                :xl="checkColumn ? 4 : 6"
                :lg="checkColumn ? 4 : 6"
                :md="checkColumn ? 4 : 6"
                sm="6"
              >
                <v-text-field
                  id="motherName"
                  name="motherName"
                  label="Nome da mãe"
                  dense
                  outlined
                  required
                  :rules="[required]"
                  v-model="model.motherName"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6">
                <v-autocomplete
                  id="nationality"
                  name="nationality"
                  label="Nacionalidade"
                  dense
                  outlined
                  :items="['Brasileiro', 'Estrangeiro']"
                  v-model="model.nationality"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="6" v-if="edit">
                <BaseMovementSituation
                  id="situation"
                  name="situation"
                  label="Status"
                  required
                  :rules="[required]"
                  :registeredStatus="registeredStatus"
                  :disabled="model.situation !== 'Em análise na operadora'"
                  v-model="model.situation"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                <v-checkbox
                  id="hasSameAddressAsTheHolder"
                  name="hasSameAddressAsTheHolder"
                  class="ma-1"
                  color="primary"
                  label="Reside no mesmo endereço do titular?"
                  v-model="model.hasSameAddressAsTheHolder"
                />
              </v-col>
            </v-row>

            <v-divider class="mb-5" />

            <v-row dense>
              <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                <h5 class="mb-4">Vigências e carteirinhas do plano</h5>
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <BaseDatePicker
                  id="dentalEffectiveStartDate"
                  name="dentalEffectiveStartDate"
                  label="Vigência odonto"
                  v-model="model.dentalEffectiveStartDate"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <BaseDatePicker
                  id="dentalBillingDate"
                  name="dentalBillingDate"
                  label="Faturamento odonto"
                  v-model="model.dentalBillingDate"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <v-text-field
                  id="dentalPlanCardNumber"
                  name="dentalPlanCardNumber"
                  label="Carteirinha de odonto"
                  dense
                  outlined
                  v-model="model.dentalPlanCardNumber"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <BaseDatePicker
                  id="healthEffectiveStartDate"
                  name="healthEffectiveStartDate"
                  label="Vigência saúde"
                  v-model="model.healthEffectiveStartDate"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <BaseDatePicker
                  id="healthBillingDate"
                  name="healthBillingDate"
                  label="Faturamento saúde"
                  v-model="model.healthBillingDate"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <v-text-field
                  id="healthPlanCardNumber"
                  name="healthPlanCardNumber"
                  label="Carteirinha de saúde"
                  dense
                  outlined
                  v-model="model.healthPlanCardNumber"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <BaseDatePicker
                  id="lifeEffectiveStartDate"
                  name="lifeEffectiveStartDate"
                  label="Vigência seguro de vida"
                  v-model="model.lifeEffectiveStartDate"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <BaseDatePicker
                  id="lifeBillingDate"
                  name="lifeBillingDate"
                  label="Faturamento seguro de vida"
                  v-model="model.lifeBillingDate"
                />
              </v-col>
            </v-row>

            <v-divider class="mb-5" v-if="!model.hasSameAddressAsTheHolder" />

            <v-row dense v-if="!model.hasSameAddressAsTheHolder">
              <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                <h5 class="mb-4">Endereço</h5>
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="4">
                <BaseZipcode
                  id="zipCode"
                  name="zipCode"
                  label="CEP"
                  required
                  :rules="[required, zipcode]"
                  v-model="model.zipCode"
                  @fill="fill($event)"
                />
              </v-col>
              <v-col cols="12" xl="8" lg="8" md="8" sm="8">
                <v-text-field
                  id="address"
                  name="address"
                  label="Endereço"
                  dense
                  outlined
                  required
                  :rules="[required]"
                  v-model="model.address"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                <v-text-field
                  id="number"
                  name="number"
                  type="number"
                  label="Número"
                  dense
                  outlined
                  required
                  :rules="[required]"
                  v-model="model.number"
                />
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="3" sm="5">
                <v-text-field
                  id="complement"
                  name="complement"
                  label="Complemento"
                  dense
                  outlined
                  v-model="model.complement"
                />
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="5">
                <v-text-field
                  id="district"
                  name="district"
                  label="Bairro"
                  dense
                  outlined
                  required
                  :rules="[required]"
                  v-model="model.district"
                />
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="4">
                <BaseStates
                  id="state"
                  name="state"
                  label="Estado"
                  required
                  :rules="[required]"
                  v-model="model.state"
                />
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="3" sm="5">
                <v-text-field
                  id="city"
                  name="city"
                  label="Cidade"
                  dense
                  outlined
                  required
                  :rules="[required]"
                  v-model="model.city"
                />
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-card-actions class="b-dialog--actions" v-if="canEdit">
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            :disabled="!valid"
            :title="edit ? 'Atualizar' : 'Salvar'"
            v-if="!isExistingDependent"
          />

          <BaseButton
            id="btn-save-link-dependent"
            name="btn-save-link-dependent"
            color="primary"
            title="Vincular"
            :disabled="!valid"
            @click="saveLinkDependent"
            v-if="isExistingDependent"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { unmask } from '@/helpers/utils';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    DialogDetachedContracts: () => import('./dialog-detached-contracts')
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    },
    selectItem: {
      type: Object,
      required: true
    },
    employeeId: {
      type: String,
      required: true
    },
    customerId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    registeredStatus: null,
    valid: true,
    dependentId: null,
    isExistingDependent: false,
    model: {
      contracts: [],
      id: null,
      kinship: null,
      name: null,
      document: null,
      birthDate: null,
      maritalStatus: null,
      gender: null,
      mobileNumber: null,
      email: null,
      nationalIdCard: null,
      nationalIdCardDispatchDate: null,
      nationalIdCardIssuer: null,
      motherName: null,
      susCardNumber: null,
      birthCertificate: null,
      eventDate: null,
      hasSameAddressAsTheHolder: false,
      zipCode: null,
      address: null,
      number: null,
      complement: null,
      district: null,
      city: null,
      state: null,
      planCardNumber: null,
      situation: null,
      dentalEffectiveDate: null,
      dentalPlanCardNumber: null,
      healthEffectiveDate: null,
      healthPlanCardNumber: null,
      hasDetachedContracts: false,
      lifeEffectiveStartDate: null,
      nationality: null,
      healthBillingDate: null,
      dentalBillingDate: null,
      lifeBillingDate: null,
      dentalEffectiveStartDate: null,
      healthEffectiveStartDate: null
    }
  }),

  created() {
    if (this.edit) {
      this.search();
    }
  },

  computed: {
    checkNationalIdRequired() {
      if (
        this.model.nationalIdCard ||
        this.model.nationalIdCardDispatchDate ||
        this.model.nationalIdCardIssuer
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkCpfRequired() {
      if (this.model.birthDate) {
        let date = new Date(this.model.birthDate);
        let age = new Date().getFullYear() - date.getFullYear();

        if (age >= 18) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    checkDnvRequired() {
      if (this.model.birthDate) {
        let date = new Date(this.model.birthDate);

        if (date.getFullYear() >= 2010) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    checkColumn() {
      return (
        this.model.maritalStatus === 'Casado(a)' ||
        this.model.maritalStatus === 'União estável'
      );
    },

    canEdit() {
      if (!this.edit) {
        return true;
      }

      const situation =
        this.model.situation === 'Ativo' ||
        this.model.situation === 'Em análise na operadora' ||
        this.model.situation === null;

      return situation;
    }
  },

  methods: {
    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getDependentById({
          employeeId: this.employeeId,
          dependentId: this.selectItem.id
        });

        if (status === 200) {
          this.registeredStatus = data.situation;
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    closeModal(redirect) {
      this.$refs.form.reset();
      this.$emit('closeModal', redirect);
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      const params = Object.assign({}, this.model);
      params.document = unmask(params.document);
      params.phoneNumber = unmask(params.phoneNumber);
      params.mobileNumber = unmask(params.mobileNumber);
      params.zipCode = unmask(params.zipCode);
      params.employeeId = this.employeeId;

      if (this.edit) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async update(params) {
      try {
        const movementService = new MovementService();
        const { status } = await movementService.updateDependent(params);

        if (status === 200 || status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async create(params) {
      try {
        const movementService = new MovementService();
        const { status } = await movementService.createDependent(params);

        if (status === 200 || status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async saveLinkDependent() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const movementService = new MovementService();
        const { status } = await movementService.saveLinkDependent({
          employeeId: this.employeeId,
          dependentId: this.dependentId,
          contracts: this.model.contracts
        });

        if (status === 200 || status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    fill(params) {
      if (params) {
        this.model.address = params.logradouro;
        this.model.district = params.bairro;
        this.model.city = params.localidade;
        this.model.state = params.uf;
      }
    },

    updatePage() {
      this.search();
    }
  }
};
</script>
